/* eslint-disable */

// change path to dark.less in App.less to switch to dark theme

import './App.less';
import './index.css';
const containerColor = "#fff"; //set to #141414 if theme is dark

import {
  LaptopOutlined,
  NotificationOutlined,
  UserOutlined,
  SettingOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import {
  Typography,
  Breadcrumb,
  Layout,
  Menu,
  Space,
  Button,
  Pagination,
  Steps,
  Checkbox,
  Input,
  Select,
  Segmented,
  Rate,
  Tabs,
  Tag,
  Alert,
  Collapse,
  Radio,
  Switch,
  Dropdown,
  Affix,
} from "antd";
import React, { useState } from "react";
import { AppstoreOutlined, MailOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';

import { Form } from 'antd';
import { AutoComplete, Drawer } from 'antd';


const { Header, Content, Sider } = Layout;





const mockVal = (str: string, repeat = 1) => ({
  value: str.repeat(repeat),
});




function Search() {
  const [value, setValue] = useState('');
  const [options, setOptions] = useState([]);

  const onSearch = (searchText: string) => {
    setOptions(
      !searchText ? [] : [mockVal(searchText), mockVal(searchText, 2), mockVal(searchText, 3)],
    );
  };

  const onSelect = (data: string) => {
    console.log('onSelect', data);
  };

  const onChange = (data: string) => {
    setValue(data);
  };


  return (
    <div>
        <Form
    className="formitems"
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: false }}
          autoComplete="off"
        >
        <Form.Item
          className="formitems"
          >
        <h2
          style={{ margin:20 }}
          
          
          >get your feed started!</h2>
                <AutoComplete
                  value={value}
                  options={options}
                  onSelect={onSelect}
                  onSearch={onSearch}
                  onChange={onChange}
                  placeholder="search domains or keywords"
                  style={{ width: 250 }}
                                  />
        </Form.Item>
        <Form.Item
                  className="formitems"
                  >
        <Button type="primary" htmlType="submit"
                  style={{ width: 250 }}
                  
                  >
          Go!
        </Button>        
        </Form.Item>
      </Form>
    </div>
  );
}

function Feed() {
  return (
    <div>&nbsp;</div>
  );
}

function Profile() {
  return (
    <div>&nbsp;</div>
  );
}





const App: React.FC = () => {

  const [tabState, setTabState] = useState(1);

  const handleTabs = (value) => {
    console.log(value)
      showDrawer()
    setTabState(value);
    return;
   }

  let tabs_array = [<>{Search()}</>, <>{Feed()}</>, <>{Profile()}</>];


  const [visible, setVisible] = useState(false);
    const [isResizing, setIsResizing] = useState(false);
    const [height, setHeight] = useState(300);
    const [mouseStart, setMouseStart] = useState({ x: 0, y: 0 });
    
    const showDrawer = () => {
      setVisible(true);
    };

    const onClose = () => {
      setVisible(false);
    };

    const onMouseDown = e => {
      setIsResizing(true);
    };

    const onMouseUp = e => {
      setIsResizing(false);
    };

    const onMouseMove = e => {
      if (isResizing) {
        
        const pixelDifference = mouseStart.y - e.offsetY;
        console.log(pixelDifference)
        setMouseStart({ x: 0, y: pixelDifference })
        //setHeight(height + pixelDifference);
        //console.log(e.clientY)
        //console.log(document.body.offsetTop)
        //let offsetTop = document.body.offsetTop - e.clientY;
        //setHeight(offsetTop);
      }
    };
    
    const [offsetTop, setOffsetTop] = useState(-40);
     const [offsetLeft, setOffsetLeft] = useState(0);
     const [xAtTouchPointStart, setXAtTouchPointStart] = useState(0);
     const [yAtTouchPointStart, setYAtTouchPointStart] = useState(0);
     const [drag, setDrag] = useState(false);
    const handleTouchStart = (e, i) => {

       e.preventDefault();
       let evt = typeof e.originalEvent === "undefined" ? e : e.originalEvent;
       let touch = evt.touches[0] || evt.changedTouches[0];
       const x = +touch.pageX;
       const y = +touch.pageY;
       console.log(
         "onTouchStart coordinates of icon @ start: X: " + x + " | Y: " + y
       );
       console.log("dragged from position n = ", i + 1);
       // get the mouse cursor position at startup:
       setXAtTouchPointStart(x);
       setYAtTouchPointStart(y);
       setDrag(true);
       //if (mouseStart.y > x)
       //setHeight(height + x)
     };
     const handleTouchEnd = (e) => {
         // if (process.env.NODE_ENV === 'debug5' || process.env.NODE_ENV === 'development') {
         e.preventDefault();
         setDrag(false);
         console.log(
           new Date(),
           "onTouchEnd event, coordinates of icon @ end: X: " +
             e.changedTouches[0]?.clientX +
             " | Y: " +
             e.changedTouches[0]?.clientY +
             " | top: " +
             top +
             " | left: " +
             left
         );
       };

       const handleElementDrag = (e) => {
         e = e || window.event;
         e.preventDefault();
         let x = 0;
         let y = 0;

         if (
           e.type === "touchstart" ||
           e.type === "touchmove" ||
           e.type === "touchend" ||
           e.type === "touchcancel"
         ) {
           let evt = typeof e.originalEvent === "undefined" ? e : e.originalEvent;
           let touch = evt.touches[0] || evt.changedTouches[0];
           x = +touch.pageX; // X Coordinate relative to the viewport of the touch point
           y = +touch.pageY; // same for Y
         } else if (
           e.type === "mousedown" ||
           e.type === "mouseup" ||
           e.type === "mousemove" ||
           e.type === "mouseover" ||
           e.type === "mouseout" ||
           e.type === "mouseenter" ||
           e.type === "mouseleave"
         ) {
           x = +e.clientX;
           y = +e.clientY;
         }
         console.log("x: ", x, "y: ", y);
         // calculate the new cursor position:
         const xRelativeToStart = x - xAtTouchPointStart;
         console.log(
           "xRel = ",
           x,
           " - ",
           xAtTouchPointStart,
           " = ",
           xRelativeToStart
         );
         const yRelativeToStart = y - yAtTouchPointStart;
         console.log(
           "yRel = ",
           y,
           " - ",
           yAtTouchPointStart,
           " = ",
           yRelativeToStart
         );

         let diff = y - yAtTouchPointStart;
         if (diff > 10) {
           diff = 10
         }
         if (diff < -10) {
           diff = -10
         } 
         let newHeight = height - diff;
         if (newHeight < 500 && newHeight > 300) {
           setHeight(newHeight)
         }
         // setXAtTouchPointStart(x); // Reseting relative point to current touch point
          //setYAtTouchPointStart(y);
         // set the element's new position:
         //setTop(yRelativeToStart + "px");
         //setLeft(xRelativeToStart + "px");
         console.log("top: ", yRelativeToStart + "px");
         console.log("Left: ", xRelativeToStart + "px");
       };


    React.useEffect(() => {
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
      return () => {
        document.removeEventListener("mousemove", onMouseMove);
        document.removeEventListener("mouseup", onMouseUp);
      };
      
    });


  return (
    <div className="app">
      <div className="top">
        <div className="header">
          <div className="hed">
            <img src="/rssdoc.png" alt="rssdoc" className="rssdoc" />
          </div>
          <div className="hed">
            <h2 className="rsshed">
              //RSS.AG
            </h2>
          </div>
        </div>
      </div>
      <div className="body">
       {tabs_array[tabState-1]}
      </div>
       
    <Drawer
       title="Results Preview"
       placement="bottom"
       closable={false}
       mask={false}
       onClose={onClose}
       visible={visible}
       height={height}
     >
       <div
         style={{
           position: "absolute",
           width: "100%",
           height:"30px",
           padding: "4px 0 0",
           top: 0,
           left: 0,
           bottom: 0,
           zIndex: 100,
           cursor: "ew-resize",
           backgroundColor: "#f4f7f9"
         }}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          onTouchMove={handleElementDrag}
       />
       <p>Tennis feeds..</p>
       <p>Art museum feeds..</p>
       <p>Portland feeds..</p>
     </Drawer>

     <Affix 
       offsetBottom={20}
     >
       <Tabs
         centered={true}
         tabPosition="bottom"
         defaultActiveKey="1"
         onChange={handleTabs}
         value={tabState}
         items={[
           {
             label: `search`,
             key: '1',
             value:1
           },
           {
             label: `feed`,
             key: '2',
             value:2
           },
           {
             label: `profile`,
             key: '3',
             value:3
           }
         ]}
       />
     </Affix>

    </div>
  );

};



export default App;